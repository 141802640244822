import { FloatingButton } from '@atoms/index'
import { Footer, NavBar } from '@organisms/index'
import { PropsWithChildren } from 'react'

interface DefaultLayoutProps extends PropsWithChildren {
  displayFloatingButton?: boolean
}
export default function DefaultLayout({
  children,
  displayFloatingButton = true
}: DefaultLayoutProps) {
  return (
    <>
      <NavBar />
      <main>{children}</main>
      <FloatingButton hide={!displayFloatingButton} />
      <Footer />
    </>
  )
}
