import { BrandLink, UserLink } from '@atoms/index'
import { Footer, UserMenu } from '@organisms/index'
import { Alert, TAlertStatus } from '@molecules/index'
import { PropsWithChildren, useState } from 'react'
import FeatherIcon from 'feather-icons-react'
import styles from './index.module.scss'
import { signOut } from 'next-auth/react'
import { useAppSessionWatchdog } from '@/hooks'

interface IUserLayoutProps extends PropsWithChildren {
  menuActiveIndex?: number
}

type TNavigationState = 'opened' | 'closed'

export default function UserLayout({
  menuActiveIndex,
  children
}: IUserLayoutProps) {
  const session = useAppSessionWatchdog()
  const userData = session.data?.user
  const [navigationState, setNavigationState] =
    useState<TNavigationState>('closed')
  const [alertStatus, setAlertStatus] = useState<TAlertStatus>('closed')
  const brandLink = userData?.roleId === 1 ? '/admin' : '/portal'
  return (
    <>
      <div
        className={styles.root}
        role={'group'}
        aria-label={'Contenedor de contenido de página'}>
        <aside
          className={styles.root__aside}
          role={'group'}
          aria-label={'Contenedor de navegación de página'}>
          <BrandLink className={styles['root__brand-link']} href={brandLink} />
          <button
            type="button"
            className={styles.root__toggle}
            title="Abrir o cerrar el menú mobile"
            onClick={() => {
              setNavigationState((currentState) =>
                currentState === 'closed' ? 'opened' : 'closed'
              )
            }}>
            <FeatherIcon
              icon={navigationState === 'closed' ? 'menu' : 'x'}
              className={styles['root__toggle-icon']}
            />
          </button>
          <nav
            className={styles.root__navigation}
            aria-label="Contenedor de items de navegación"
            data-state={navigationState}>
            <UserLink
              {...{
                firstName: userData?.firstname,
                lastName: userData?.lastname,
                picture:
                  userData?.photoProfile === null
                    ? undefined
                    : userData?.photoProfile
              }}
              active={menuActiveIndex === -1}
            />
            <span className={styles['root__nav-title']}>Menú</span>
            <UserMenu
              activeIndex={menuActiveIndex}
              onButtonClick={(action) => {
                if (action === 'logout') setAlertStatus('open')
              }}
            />
          </nav>
        </aside>
        <main>{children}</main>
        <Alert
          type="logout"
          status={alertStatus}
          onResponse={async (response) => {
            if (response) {
              await signOut({ callbackUrl: '/ingreso' })
            } else setAlertStatus('closed')
          }}
        />
      </div>
      <Footer alternative />
    </>
  )
}
