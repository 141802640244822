import { useEffect } from 'react'
import { GetStaticPropsResult } from 'next'
import { SEO, setBrands } from '@molecules/index'
import {
  BrandsSection,
  BmodelSection,
  BenefitsSection,
  setBenefits,
  setBanners,
  TestimonialsSection,
  FaqsSection,
  MetricsSection
} from '@organisms/index'
import { DefaultLayout } from '@templates/index'
import { useAppDispatch } from '@/state/hooks'
import {
  IBanner,
  IBannersListCollection,
  IBenefit,
  IBenefitsListCollection,
  IBrand,
  IBrandsListCollection,
  IFaq,
  IFaqsListCollection,
  ITestimonial,
  ITestimonialsListCollection
} from '@/interfaces'
import { apolloClient, GET_HOME_DATA } from '@/client'
import HeroSection from '@organisms/hero-section'
import EarnSection from '@organisms/earn-section'
import AdSection from '@organisms/ad-section'

interface IHomeProps {
  brands?: IBrand[]
  benefits?: IBenefit[]
  testimonials?: ITestimonial[]
  faqs?: IFaq[]
  banners?: IBanner[]
}

export default function Home({
  brands = [],
  benefits = [],
  testimonials = [],
  faqs = [],
  banners = []
}: IHomeProps) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setBrands(brands))
    dispatch(setBenefits(benefits))
    dispatch(setBanners(banners))
  }, [dispatch, brands, benefits, banners])

  return (
    <>
      <SEO
        title="Ventas por catálogo"
        description="Descubre el poder de Kliiker y aumenta tus ingresos con nuestras ventas por catálogo. ¡Únete a nuestro equipo y comienza a ganar ahora mismo!"
      />
      <DefaultLayout>
        <HeroSection />
        <BmodelSection />
        <EarnSection />
        <BrandsSection />
        <BenefitsSection />
        <AdSection />
        <TestimonialsSection testimonials={testimonials} />
        <MetricsSection />
        <FaqsSection faqs={faqs} />
      </DefaultLayout>
    </>
  )
}

export async function getStaticProps(): Promise<
  GetStaticPropsResult<IHomeProps>
> {
  const props: IHomeProps = {}

  await apolloClient
    .query<{
      brandsListCollection: IBrandsListCollection
      benefitsListCollection: IBenefitsListCollection
      testimonialsListCollection: ITestimonialsListCollection
      faqsListCollection: IFaqsListCollection
      bannersListCollection: IBannersListCollection
    }>({
      query: GET_HOME_DATA
    })
    .then(async ({ data }: any) => {
      props.brands =
        data.brandsListCollection.items === undefined
          ? []
          : data.brandsListCollection.items[0].brandsCollection?.items

      props.benefits =
        data.benefitsListCollection.items === undefined
          ? []
          : data.benefitsListCollection.items[0].benefitsCollection?.items

      props.testimonials =
        data.testimonialsListCollection.items === undefined
          ? []
          : data.testimonialsListCollection.items[0].testimonialsCollection
            ?.items

      props.faqs =
        data.faqsListCollection.items === undefined
          ? []
          : data.faqsListCollection.items[0].faqsCollection?.items

      props.banners =
        data.bannersListCollection.items === undefined
          ? []
          : data.bannersListCollection.items[0].bannersCollection?.items

      await apolloClient.resetStore()
    })
    .catch((error: any) => console.log(error))

  return { props, revalidate: 10 }
}
