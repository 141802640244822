import styles from './index.module.scss'
import Link from 'next/link'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { useAppSelector } from '@/state/hooks'
import { IBanner } from '@/interfaces'
import { selectBanners } from '..'

export default function AdSection() {
  const listBanner: IBanner[] = useAppSelector(selectBanners)

  const srcDesktop = listBanner[2]?.image?.url
  const srcMobile = listBanner[2]?.logo?.url
  const [screenWidth, setScreenWidth] = useState(0)
  const [imagenUrl, setImagenUrl] = useState('')
  const [datosCargados, setDatosCargados] = useState(false)

  const cambiarEstilos = (src: string | undefined) => {
    const setSrc: string = src !== undefined ? src : ''
    setImagenUrl(setSrc)
  }

  useEffect(() => {
    function handleResize() {
      const newScreenWidth = window.innerWidth
      setScreenWidth(newScreenWidth)
      const setSrc: string | undefined =
        newScreenWidth <= 767 ? srcMobile : srcDesktop
      cambiarEstilos(setSrc)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [srcDesktop, srcMobile, screenWidth])

  useEffect(() => {
    if (listBanner.length > 0) {
      setDatosCargados(true)
    }
  }, [listBanner])

  useEffect(() => {
    if (datosCargados) {
      cambiarEstilos(screenWidth <= 767 ? srcMobile : srcDesktop)
    }
  }, [datosCargados, srcDesktop, srcMobile, screenWidth])

  return (
    <section role={'contentinfo'} className={`${styles.root} `}>
      <Link
        href={'/registro'}
        title={'Enlace al formulario de registro Kliiker'}>
        <div style={{ width: '100%', height: 'auto' }}>
          {imagenUrl !== '' && (
            <Image
              className={styles.root__image}
              src={imagenUrl}
              width={1500}
              height={200}
              alt={''}
            />
          )}
        </div>
      </Link>
    </section>
  )
}
