import { BrandLink } from '@atoms/index'
import { Footer } from '@organisms/index'
import { PropsWithChildren } from 'react'
import styles from './index.module.scss'

interface IIntermediateLayoutProps extends PropsWithChildren {
  hideFooter?: boolean
}

export default function IntermediateLayout({
  children,
  hideFooter = false
}: IIntermediateLayoutProps) {
  return (
    <>
      <div
        className={styles['root__content-wrapper']}
        role={'group'}
        aria-label={'Contenedor de contenido de página'}>
        <aside
          className={styles['root__aside-navigation']}
          role={'navigation'}
          aria-label={'Barra de navegación lateral de sitio'}>
          <BrandLink className={styles['root__brand-link']} />
        </aside>
        <main>{children}</main>
      </div>
      {!hideFooter && <Footer alternative />}
    </>
  )
}
